import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BackLink, ClientLink, Currency, DateTime, ExportDropdown, PeriodFilter, ProjectLink } from '~/components';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import CurrencyFilter from '~/components/filters/CurrencyFilter';
import FilterButton from '~/components/filters/FilterButton';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import SingleSelectFilter from '~/components/filters/SingleSelectFilter';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import projectStatuses from '~/lookups/project-statuses.js';
import { PageLoader } from '~/routes/public/pages';
import { weights } from '~/styles';
import { dateFormats, mimeTypes } from '~/utils';
import ExportDialogAsync from '../components/ExportDialogAsync';
import Report from '../components/Report';
import Table from '../components/table';
import SavedReportActions from '../SavedReportActions';
import { intervals, intervalsByScope } from './intervals';

const Byline = styled.small`
  display: block;
`;

export default function ServicesRevenuePlanByClientAndProject({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const searchParamsConfig = useSearchParamsConfig();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectRecordStatusId: {
      ...searchParamsConfig.recordStatusId,
      default: 'active',
    },
    projectStatuses: {
      ...searchParamsConfig.projectStatuses,
      default: [projectStatuses.not_started, projectStatuses.in_progress],
    },
  }));

  const [params, setParams] = useState({
    period: null,
    unit: 'month',
    currency: workspace.currency,
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          ...searchParamsConfig.periodByUnit,
          default: intervals.next_6_months,
          intervals: (unit) => intervalsByScope[unit],
          defaults: {
            day: intervals.next_30_days,
            week: intervals.next_12_weeks,
            month: intervals.next_6_months,
          },
        },
        unit: { default: 'month', valid: ['day', 'week', 'month'] },
        currency: searchParamsConfig.currency,
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters, projectFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      unit: params.unit,
      currency: params.currency ?? undefined,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().servicesRevenuePlanByProject(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(values);
    hideFilters();
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };
  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .servicesRevenuePlanByProject(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title report={report} />
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />

          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <PeriodFilter
          clearable={false}
          scope={params.unit}
          intervals={intervalsByScope[params.unit]}
          placeholder="Date Range"
          value={params.period}
          onChange={({ target: { value } }) => handleApplyFilters({ period: value })}
        />

        <UnitFilter
          value={params.unit}
          onChange={({ target: { value } }) =>
            handleApplyFilters({
              unit: value,
              period: {
                day: intervals.next_30_days,
                week: intervals.next_12_weeks,
                month: intervals.next_6_months,
              }[value],
            })
          }
        />

        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params }) {
  const report = query.data;

  const { clientsById, projectsById, revenuePeriods, periods } = useMemo(() => {
    const clientsById = _(report.projects)
      .map((p) => p.client)
      .keyBy('id')
      .value();

    const projectsById = _.keyBy(report.projects, 'id');

    const revenuePeriods = _.orderBy(report.periods, (a) => [
      projectsById[a.projectId].client.name.toLowerCase(),
      projectsById[a.projectId].name.toLowerCase(),
    ]).reduce(
      (a, v) => {
        a.total += v.convertedRevenue;

        const project = projectsById[v.projectId];
        const client = project.client;

        // Clients
        a.clients[client.id] = a.clients[client.id] || { total: 0, periods: {}, projects: {} };
        a.clients[client.id].total += v.convertedRevenue;
        if (!a.clients[client.id].periods[v.start]) a.clients[client.id].periods[v.start] = 0;
        a.clients[client.id].periods[v.start] += v.convertedRevenue;
        a.clients[client.id].projects[v.projectId] = a.clients[client.id].projects[v.projectId] || {
          total: 0,
          convertedTotal: 0,
          periods: {},
        };

        // Projects
        a.clients[client.id].projects[v.projectId].total += v.revenue;
        a.clients[client.id].projects[v.projectId].convertedTotal += v.convertedRevenue;
        a.clients[client.id].projects[v.projectId].periods[v.start] = {
          revenue: v.revenue,
          convertedRevenue: v.convertedRevenue,
        };

        // Periods
        if (!a.periods[v.start]) a.periods[v.start] = 0;
        a.periods[v.start] += v.convertedRevenue;

        return a;
      },
      { clients: {}, periods: {}, total: 0 },
    );

    let start = params.period.start;
    let end = params.period.end;

    switch (params.unit) {
      case 'day':
        start = moment(start).format(dateFormats.isoDate);
        end = moment(end).format(dateFormats.isoDate);
        break;

      case 'week':
        start = moment(start).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(end).endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'month':
        start = moment(start).startOf('month').format(dateFormats.isoDate);
        end = moment(end).endOf('month').format(dateFormats.isoDate);
        break;
    }

    const periodCount = moment(end).diff(start, params.unit) + 1;
    const periods = [];
    for (let index = 0; index < periodCount; index++) {
      periods.push(moment(start).add(index, params.unit).format(dateFormats.isoDate));
    }

    return { clientsById, projectsById, revenuePeriods, periods };
  }, [report, params.period.start, params.period.end, params.unit]);

  return (
    <Report.Table>
      <Table>
        <Table.Header>
          <Table.Column sticky minWidth="16rem">
            Client
          </Table.Column>

          {periods.map((p) => (
            <Table.Column key={p} align="right" width="10rem">
              <DateTime value={p} />
            </Table.Column>
          ))}

          <Table.Column align="right" width="10rem">
            Total
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.loading.table}>
          {_.map(revenuePeriods.clients, (clientRevenue, clientId) => {
            const client = clientsById[clientId];

            return (
              <React.Fragment key={client.id}>
                <Table.GroupRow top="3rem">
                  <Table.Cell>
                    <ClientLink client={client} />
                  </Table.Cell>

                  {periods.map((period) => {
                    const revenue = clientRevenue.periods[period];

                    return (
                      <Table.Cell key={period}>
                        <Currency value={revenue} currency={report.currency} />
                      </Table.Cell>
                    );
                  })}

                  <Table.Cell>
                    <Currency value={clientRevenue.total} currency={report.currency} />
                  </Table.Cell>
                </Table.GroupRow>

                {_.map(clientRevenue.projects, (projectRevenue, projectId) => {
                  const project = projectsById[projectId];

                  return (
                    <Table.Row key={projectId}>
                      <Table.Cell>
                        <p style={{ marginLeft: '1rem' }}>
                          <ProjectLink project={project} />
                        </p>
                      </Table.Cell>

                      {periods.map((period) => {
                        const { revenue, convertedRevenue } = projectRevenue.periods[period] || {};

                        return (
                          <Table.Cell key={period}>
                            <p>
                              <Currency value={convertedRevenue} currency={report.currency} />
                              {project.currency !== report.currency && (
                                <Byline>
                                  <Currency value={revenue} currency={project.currency} />
                                </Byline>
                              )}
                            </p>
                          </Table.Cell>
                        );
                      })}

                      <Table.Cell>
                        <p>
                          <Currency value={projectRevenue.convertedTotal} currency={report.currency} />
                          {project.currency !== report.currency && (
                            <Byline>
                              <Currency value={projectRevenue.total} currency={project.currency} />
                            </Byline>
                          )}
                        </p>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })}
          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>

            {periods.map((period) => {
              const revenue = revenuePeriods.periods[period];

              return (
                <Table.Cell key={period}>
                  <Currency value={revenue} currency={report.currency} />
                </Table.Cell>
              );
            })}

            <Table.Cell>
              <Currency value={revenuePeriods.total} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>

        <Table.Status
          total={_.size(clientsById)}
          label="Client"
          isLoading={query.status !== 'ready'}
          partial={report.partialResults}
        />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <UnitFilter
        value={filters.unit}
        onChange={({ target: { value } }) =>
          handleFilter({
            unit: value,
            period: {
              day: intervals.next_30_days,
              week: intervals.next_12_weeks,
              month: intervals.next_6_months,
            }[value],
          })
        }
      />

      <PeriodFilter
        clearable={false}
        scope={filters.unit}
        intervals={intervalsByScope[filters.unit]}
        placeholder="Date Range"
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

function UnitFilter(props) {
  return (
    <SingleSelectFilter
      icon="gear"
      placeholder="Unit"
      clearable={false}
      renderValue={(value) => value.name}
      options={[
        { id: 'month', name: 'Month' },
        { id: 'week', name: 'Week' },
        { id: 'day', name: 'Day' },
      ]}
      {...props}
    />
  );
}
