import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  ApprovalStatusFilter,
  BackLink,
  ClientApprovalStatusFilter,
  ClientLink,
  Currency,
  DateTime,
  ExpenseLockIcon,
  Icon,
  InternalClientTooltip,
  MultiSelect,
  ProjectLink,
  TimeLockIcon,
  Tooltip,
  ExportDropdown,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import clientApprovalStatuses from '~/lookups/client-approval-statuses';
import ExpenseItemDrawer from '~/routes/app/expenses/item/ExpenseItemDrawer';
import ProjectDrawer from '~/routes/app/projects/project-drawer/ProjectDrawer';
import EditTimeEntry from '~/routes/app/time/edit-time-entry';
import ViewTimeEntry from '~/routes/app/time/view-time-entry';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { intervalOptions, QuerySort } from '~/utils';
import { sumBy } from '~/utils/math';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import CurrencyFilter from '~/components/filters/CurrencyFilter';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import FiltersGroup from '~/components/filters/FiltersGroup';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter';
import Table from '../components/table';
import TaskFiltersBar from '~/components/filters/TaskFiltersBar';
import TaskFiltersGroup from '~/components/filters/TaskFiltersGroup';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import SavedReportActions from '../SavedReportActions';

const ClientInfo = styled.div`
  display: flex;
`;

const ProjectInfo = styled.div`
  line-height: 1;
  margin-left: 1rem;
`;

const Byline = styled.small`
  display: block;
`;

export default function UninvoicedRevenue({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectRecordStatusId: { ...searchParamsConfig.recordStatusId, default: 'active' },
  }));

  const [params, setParams] = useState({
    period: null,
    itemTypes: [],
    projectTaskStatuses: [],
    timeApprovalStatuses: [],
    timeClientApprovalStatuses: [],
    expenseApprovalStatuses: [],
    expenseClientApprovalStatuses: [],
    currency: workspace.currency,
    sort: new QuerySort('project:client.name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { ...reportsSearchParamsConfig.period, default: intervalOptions.this_month },
        itemTypes: {
          multi: true,
          key: 'itemType',
          valid: _.keys(itemTypes),
          serialize: (value) => value?.map((v) => v.id),
          deserialize: (ids) => ids.map((id) => itemTypes[id]),
        },
        projectTaskStatuses: { ...searchParamsConfig.projectTaskStatuses, key: 'projectTaskStatus' },
        timeApprovalStatuses: { ...searchParamsConfig.approvalStatuses, key: 'timeStatus' },
        timeClientApprovalStatuses: { ...searchParamsConfig.clientApprovalStatuses, key: 'timeClientStatus' },
        expenseApprovalStatuses: { ...searchParamsConfig.approvalStatuses, key: 'expenseStatus' },
        expenseClientApprovalStatuses: { ...searchParamsConfig.clientApprovalStatuses, key: 'expenseClientStatus' },
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('project:client.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      itemType: params.itemTypes?.map((v) => v.id),
      projectTaskStatusId: params.projectTaskStatuses?.map((v) => v.id),
      timeStatusId: params.timeApprovalStatuses?.map((v) => v.id),
      timeClientApprovalStatusId: features.clientApprovals
        ? params.timeClientApprovalStatuses?.map((v) => v.id)
        : undefined,
      expenseStatusId: params.expenseApprovalStatuses?.map((v) => v.id),
      expenseClientApprovalStatusId: features.clientApprovals
        ? params.expenseClientApprovalStatuses?.map((v) => v.id)
        : undefined,
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters, features.clientApprovals],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().uninvoicedRevenue(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: {
        table: false,
      },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .uninvoicedRevenue(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title report={report} />
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />

          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ReportPeriodFilter
          value={params.period}
          onChange={({ target: { value } }) => handleApplyFilters({ period: value })}
        />
        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}
        {!_.isEmpty(params.itemTypes) && (
          <ItemTypeFilter
            value={params.itemTypes}
            onChange={({ target: { value } }) => handleApplyFilters({ itemTypes: value })}
          />
        )}
        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />
        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
        <TaskFiltersBar filters={params} onChange={handleApplyFilters} />
        {/* TODO: these filters should adhere to the standard naming convention */}
        {!_.isEmpty(params.timeApprovalStatuses) && (
          <ApprovalStatusFilter
            value={params.timeApprovalStatuses}
            onChange={({ target: { value } }) => handleApplyFilters({ timeApprovalStatuses: value })}
          />
        )}
        {!_.isEmpty(params.timeClientApprovalStatuses) && (
          <ClientApprovalStatusFilter
            value={params.timeClientApprovalStatuses}
            onChange={({ target: { value } }) => handleApplyFilters({ timeClientApprovalStatuses: value })}
          />
        )}
        {!_.isEmpty(params.expenseApprovalStatuses) && (
          <ApprovalStatusFilter
            value={params.expenseApprovalStatuses}
            onChange={({ target: { value } }) => handleApplyFilters({ expenseApprovalStatuses: value })}
          />
        )}
        {!_.isEmpty(params.expenseClientApprovalStatuses) && (
          <ClientApprovalStatusFilter
            value={params.expenseClientApprovalStatuses}
            onChange={({ target: { value } }) => handleApplyFilters({ expenseClientApprovalStatuses: value })}
          />
        )}
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data
                  query={query}
                  params={params}
                  onSort={handleSort}
                  onReload={fetchData}
                  onCurrencyChange={handleCurrencyChange}
                />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort, onReload }) {
  const documentTitle = useDocumentTitle();

  const report = query.data;
  const features = useFeatures();

  const groups = useMemo(() => {
    return report.records.reduce((a, v) => {
      a[v.project.client.id] = a[v.project.client.id] || { client: v.project.client, projects: {} };

      a[v.project.client.id].projects[v.project.id] = a[v.project.client.id].projects[v.project.id] || {
        project: v.project,
        items: [],
      };

      a[v.project.client.id].projects[v.project.id].items.push(v);

      return a;
    }, {});
  }, [report.records]);

  const total = useMemo(() => sumBy(report.records, 'convertedAmount'), [report.records]);

  const api = useApi();
  const { workspace } = useWorkspace();
  const [dialog, setDialog] = useState(null);
  const handleCloseDialog = () => {
    setDialog(null);
    documentTitle.set('Uninvoiced Revenue');
  };
  const route = useRouteMatch();
  const history = useHistory();

  return (
    <>
      <Report.Table>
        <Table>
          <Table.Header>
            <Table.Column sticky minWidth="16rem" name="project:client.name" onSort={onSort} sort={params.sort}>
              Client
            </Table.Column>
            <Table.Column width="7.5rem">Date</Table.Column>
            <Table.Column minWidth="16rem">Details</Table.Column>
            <Table.Column width="12rem" isVisible={features.clientApprovals}>
              Client Approval Status
            </Table.Column>
            <Table.Column width="9rem">Approval Status</Table.Column>
            <Table.Column width="10rem" align="right">
              Amount
            </Table.Column>
          </Table.Header>

          <Table.Body fade={query.loading.table}>
            {_.map(groups, ({ client, projects }) => {
              const total = sumBy(
                _.flatMap(projects, (project) => project.items),
                'convertedAmount',
              );

              return (
                <React.Fragment key={client.id}>
                  <Table.GroupRow top="4.05rem">
                    <Table.Cell style={{ flex: 6 }}>
                      <strong>
                        <ClientInfo>
                          <ClientLink client={client} />
                          {client.isInternal && <InternalClientTooltip />}
                        </ClientInfo>
                      </strong>
                    </Table.Cell>

                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />

                    <Table.Cell>
                      <strong>
                        <Currency value={total} currency={report.currency} />
                      </strong>
                    </Table.Cell>
                  </Table.GroupRow>

                  {_.map(projects, ({ project, items }) => {
                    const total = sumBy(items, 'amount');
                    const convertedTotal = sumBy(items, 'convertedAmount');

                    return (
                      <React.Fragment key={project.id}>
                        <Table.Row>
                          <Table.Cell style={{ flex: 6 }}>
                            <ProjectInfo>
                              <strong>
                                <ProjectLink project={project} />
                              </strong>
                            </ProjectInfo>
                            {!project.permissions.edit && (
                              <Tooltip
                                message="You do not have permissions to edit this project."
                                style={{ marginLeft: '.5rem', display: 'flex', alignItems: 'center' }}>
                                <Icon icon="fa-circle-info" color={colors.grey25} />
                              </Tooltip>
                            )}
                          </Table.Cell>

                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />

                          <Table.Cell>
                            <p>
                              <strong>
                                <Currency value={convertedTotal} currency={report.currency} />
                              </strong>
                              {project.currency !== report.currency && (
                                <Byline>
                                  <strong>
                                    <Currency value={total} currency={project.currency} />
                                  </strong>
                                </Byline>
                              )}
                            </p>
                          </Table.Cell>
                        </Table.Row>

                        {items.map((item) => {
                          const { id, date, type, typeKey, details, amount, convertedAmount } = item;

                          let handleClick;

                          switch (typeKey) {
                            case 'time_entry': {
                              handleClick = () => {
                                if (item.timeEntry.isLocked) {
                                  setDialog(<ViewTimeEntry id={id} onClose={handleCloseDialog} />);
                                } else {
                                  setDialog(
                                    <EditTimeEntry
                                      id={id}
                                      memberId={item.timeEntry.memberId}
                                      onSubmit={(body) => api.www.workspaces(workspace.id).timeAdmin(id).update(body)}
                                      onConfirmDelete={(id) => api.www.workspaces(workspace.id).timeAdmin(id).delete()}
                                      onSaved={onReload}
                                      onDeleted={onReload}
                                      onTimerChange={onReload}
                                      onClose={handleCloseDialog}
                                    />,
                                  );
                                }
                              };

                              break;
                            }

                            case 'expense_item': {
                              handleClick = () => {
                                history.push({
                                  pathname: `${route.url}/${id}`,
                                  search: location.search,
                                  state: { scrollToTop: false },
                                });
                              };

                              break;
                            }

                            case 'fixed_fee_milestone':
                            case 'project_expense_item':
                            case 'other_item': {
                              if (project.permissions.edit) {
                                handleClick = () =>
                                  setDialog(
                                    <ProjectDrawer
                                      projectId={project.id}
                                      selectedTabIndex={3}
                                      onSaved={onReload}
                                      onDeleted={onReload}
                                      onClose={handleCloseDialog}
                                    />,
                                  );
                              }

                              break;
                            }
                          }

                          return (
                            <Table.Row key={id} onClick={handleClick}>
                              <Table.Cell>
                                <div style={{ width: '1rem' }}>
                                  <StatusIcon item={item} />
                                </div>
                                <div style={{ marginLeft: '1rem' }}>{type}</div>
                              </Table.Cell>
                              <Table.Cell>
                                <DateTime value={date} />
                              </Table.Cell>
                              <Table.Cell>
                                <p style={{ whiteSpace: 'pre-wrap' }}>{details}</p>
                              </Table.Cell>
                              <Table.Cell>
                                {{
                                  time_entry: clientApprovalStatuses[item.timeEntry?.clientApprovalStatusId]?.name,
                                  expense_item: clientApprovalStatuses[item.expenseItem?.clientApprovalStatusId]?.name,
                                }[typeKey] ?? null}
                              </Table.Cell>
                              <Table.Cell>
                                {{
                                  time_entry: item.timeEntry?.status.name,
                                  expense_item: item.expenseItem?.status.name,
                                }[typeKey] ?? null}
                              </Table.Cell>
                              <Table.Cell>
                                <p>
                                  <Currency value={convertedAmount} currency={report.currency} />
                                  {project.currency !== report.currency && (
                                    <Byline>
                                      <Currency value={amount} currency={project.currency} />
                                    </Byline>
                                  )}
                                </p>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}

            <Table.Row>
              <Table.Cell>
                <strong>Total</strong>
              </Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell>
                <strong>
                  <Currency value={total} currency={report.currency} />
                </strong>
              </Table.Cell>
            </Table.Row>
          </Table.Body>

          <Table.Status
            total={_.size(groups)}
            label="Client"
            isLoading={query.status !== 'ready'}
            partial={report.partialResults}
          />
        </Table>
      </Report.Table>

      {dialog}

      <Route path={route.path.concat('/:expenseItemId')}>
        <ExpenseItemDrawer
          onSaved={onReload}
          onDeleted={onReload}
          onClose={() => {
            history.push({ pathname: route.url, search: location.search, state: { scrollToTop: false } });
            documentTitle.set('Uninvoiced Revenue');
          }}
        />
      </Route>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);
  const features = useFeatures();

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ReportPeriodFilter
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      <ItemTypeFilter
        value={filters.itemTypes}
        onChange={({ target: { value } }) => handleFilter({ itemTypes: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <TaskFiltersGroup filters={filters} onChange={handleFilter} />

      {/* TODO: these filters should adhere to the standard naming convention */}
      <FiltersGroup label="Time Filters" filters={[filters.timeApprovalStatuses, filters.timeClientApprovalStatuses]}>
        <ApprovalStatusFilter
          value={filters.timeApprovalStatuses}
          onChange={({ target: { value } }) => handleFilter({ timeApprovalStatuses: value })}
        />

        {features.clientApprovals && (
          <ClientApprovalStatusFilter
            value={filters.timeClientApprovalStatuses}
            onChange={({ target: { value } }) => handleFilter({ timeClientApprovalStatuses: value })}
          />
        )}
      </FiltersGroup>

      {/* TODO: these filters should adhere to the standard naming convention */}
      <FiltersGroup
        label="Expense Filters"
        filters={[filters.expenseApprovalStatuses, filters.expenseClientApprovalStatuses]}>
        <ApprovalStatusFilter
          value={filters.expenseApprovalStatuses}
          onChange={({ target: { value } }) => handleFilter({ expenseApprovalStatuses: value })}
        />

        {features.clientApprovals && (
          <ClientApprovalStatusFilter
            value={filters.expenseClientApprovalStatuses}
            onChange={({ target: { value } }) => handleFilter({ expenseClientApprovalStatuses: value })}
          />
        )}
      </FiltersGroup>
    </Report.FiltersDrawer>
  );
}

const itemTypes = {
  time_entry: { id: 'time_entry', name: 'Time Entry' },
  expense_item: { id: 'expense_item', name: 'Expense Item' },
  fixed_fee_milestone: { id: 'fixed_fee_milestone', name: 'Fixed Fee Milestone' },
  other_item: { id: 'other_item', name: 'Other Item' },
  project_expense_item: { id: 'project_expense_item', name: 'Project Expense Item' },
};

function ItemTypeFilter({ materialPlaceholder = 'Item Type', placeholder = 'All', name, value, onChange }) {
  return (
    <MultiSelect.Filter
      materialAlwaysVisible
      materialPlaceholder={materialPlaceholder}
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.map((v) => v.name).join('; ')}
      options={_.values(itemTypes)}
      onChange={onChange}
    />
  );
}

function StatusIcon({ item }) {
  switch (item.typeKey) {
    case 'time_entry':
      return <TimeLockIcon value={item.timeEntry.lockStatusId} />;

    case 'expense_item':
      return <ExpenseLockIcon value={item.expenseItem.lockStatusId} />;

    default:
      return null;
  }
}
