import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BackLink, DateTime, Hours, BillableIcon, YesNoFilter, ExportDropdown } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { dateFormats, intervalOptions, mimeTypes } from '~/utils';
import ExportDialogAsync from '../components/ExportDialogAsync';
import Report from '../components/Report';
import Table from '../components/table';
import { colors, weights } from '~/styles';
import styled from 'styled-components';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import { ReportPeriodFilter, DayPickerFilter, SingleProjectFilter } from '~/components/filters';
import SavedReportActions from '../SavedReportActions';

export default function ProjectTimeStatement({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    params: {
      period: null,
      date: null,
      project: null,
    },
    loading: {
      table: false,
    },
  });

  const params = query.params;

  const setParams = (params) => {
    setQuery((state) => ({
      ...state,
      params: { ...state.params, ...params },
    }));
  };

  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { ...reportsSearchParamsConfig.period, default: intervalOptions.last_month },
        date: {
          default: moment().format(dateFormats.isoDate),
          deserialize: (value) => {
            if (!moment(value, true).isValid()) {
              value = null;
            }
            return value;
          },
        },
        project: searchParamsConfig.project,
      }),
      [searchParamsConfig, reportsSearchParamsConfig],
    ),

    onChange: (params) => setParams(params),
  });

  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      date: params.date ?? undefined,
      projectId: params.project ? params.project.id : undefined,
    }),
    [params],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams(params);
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    if (!urlSearchParams.projectId) {
      setQuery((state) => ({
        ...state,
        data: {},
        status: 'ready',
        loading: { table: false },
      }));
      return;
    }
    const { data } = await api.www.workspaces(workspace.id).reports().projectTimeStatement(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [showNotes, setShowNotes] = useState(false);

  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values, page: 0 });
    searchParams.set(values);
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .projectTimeStatement(
            { ...urlSearchParams, showNotes: showNotes ? 'yes' : 'no' },
            {
              headers: { accept: mimeType },
            },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title report={report} />
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} />
          <ExportDropdown>
            {({ setIsOpen }) => {
              const disabled = !query.data;

              return (
                <>
                  <ExportDropdown.Item
                    tooltip={disabled ? 'Missing required filters' : undefined}
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    tooltip={disabled ? 'Missing required filters' : undefined}
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    tooltip={disabled ? 'Missing required filters' : undefined}
                    onClick={async () => {
                      await handleExport(mimeTypes.pdf);
                      setIsOpen(false);
                    }}>
                    Export to PDF
                  </ExportDropdown.Item>
                </>
              );
            }}
          </ExportDropdown>
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar style={{ marginBottom: '2rem' }}>
        <SingleProjectFilter
          name="project"
          placeholder="Project"
          materialAlwaysVisible
          materialPlaceholder="Project"
          activeOnly={false}
          permission="viewTimeAndExpenses"
          value={params.project}
          clearable={false}
          onChange={({ target: { value } }) => handleApplyFilters({ project: value })}
        />

        <ReportPeriodFilter
          value={params.period}
          onChange={({ value }) => handleApplyFilters({ period: value })}
          clearable={false}
        />

        <DayPickerFilter
          value={params.date}
          name="date"
          placeholder="Statement Date"
          clearable={false}
          scope={'day'}
          onChange={({ target: { value } }) => handleApplyFilters({ date: value })}
        />

        <YesNoFilter
          icon="gear"
          placeholder="Show Notes"
          value={showNotes ? 'yes' : 'no'}
          clearable={false}
          onChange={({ target: { value } }) => setShowNotes(value === 'yes')}
        />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return <Data query={query} params={params} showNotes={showNotes} />;
        }
      })()}
    </Report>
  );
}

const Byline = styled.small`
  display: block;
`;

const TimeEntryNotes = styled.p`
  flex-basis: 100%;
  padding: 0 0.5rem 1rem 4.5rem;
  text-justify: inter-word;
  color: ${colors.grey55};
  font-size: 0.75rem;
  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;

function Data({ query, params, showNotes }) {
  const { rows, periods, project } = query.data;

  if (!params.project) return;

  const useBudget = project.useBudget;

  let budgetHoursLeft = useBudget ? project.budget.hours - periods.startingHours : null;

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Column width="3.5rem">
            <BillableIcon value={true} />
          </Table.Column>
          <Table.Column width="25rem" name="date">
            Date
          </Table.Column>
          <Table.Column name="member" width="25rem">
            Member
          </Table.Column>
          <Table.Column width="25rem">Role/Task </Table.Column>
          <Table.Column align="right">Hours</Table.Column>
          <Table.Column isVisible={useBudget} width="10rem" align="right">
            Budget Hours Remaining
          </Table.Column>
        </Table.Header>
        <Table.Body>
          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              <DateTime value={params.start} />
            </Table.Cell>
            <Table.Cell>Hours Prior to the Period</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              <Hours value={periods.startingHours} />
            </Table.Cell>
            <Table.Cell>
              <Hours value={budgetHoursLeft} />
            </Table.Cell>
          </Table.Row>
          {rows.map((entry) => {
            const hasNotes = !!(showNotes && entry.notes);

            budgetHoursLeft = useBudget ? budgetHoursLeft - entry.hours : null;

            return (
              <Table.Row style={{ flexWrap: 'wrap' }} key={entry.id}>
                <Table.Cell>
                  <BillableIcon value={entry.isActuallyBillable} />
                </Table.Cell>
                <Table.Cell>
                  <DateTime value={entry.date} />
                </Table.Cell>
                <Table.Cell>{entry.member.name}</Table.Cell>
                <Table.Cell>
                  <p>
                    {entry.project?.useRoles && entry.role?.name}
                    {entry.task && <Byline>{entry.task.name}</Byline>}
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <Hours value={entry.hours} />
                </Table.Cell>
                <Table.Cell>
                  <Hours value={budgetHoursLeft} />
                </Table.Cell>
                {hasNotes && <TimeEntryNotes>{entry.notes}</TimeEntryNotes>}
              </Table.Row>
            );
          })}
          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              <DateTime value={params.end} />
            </Table.Cell>
            <Table.Cell>Hours Through the Period</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              <Hours value={periods.endingHours} />
            </Table.Cell>
            <Table.Cell>
              <Hours value={budgetHoursLeft} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}
