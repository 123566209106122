import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BackLink,
  ClientLink,
  DateTime,
  InternalClientTooltip,
  ProjectHealthCircle,
  ProjectHealthScoreBar,
  ProjectLink,
  ExportDropdown,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import ProjectHealthReportForm from '~/routes/app/projects/health-tab/ProjectHealthReportForm';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import Report from '../components/Report';
import Table from '../components/table';
import SavedReportActions from '../SavedReportActions';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

function ProjectHealthByProject({ report, backLinkPath }) {
  const documentTitle = useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectRecordStatusId: { ...searchParamsConfig.recordStatusId, default: 'active' },
  }));

  const [params, setParams] = useState({
    projectBudgetHealth: [],
    projectScheduleHealth: [],
    projectClientSatisfaction: [],
    projectTeamSatisfaction: [],
    sort: new QuerySort('project.name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        projectBudgetHealth: searchParamsConfig.projectBudgetHealth,
        projectScheduleHealth: searchParamsConfig.projectScheduleHealth,
        projectClientSatisfaction: searchParamsConfig.projectClientSatisfaction,
        projectTeamSatisfaction: searchParamsConfig.projectTeamSatisfaction,
        sort: { default: new QuerySort('project.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters, projectFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      projectBudgetHealthId: params.projectBudgetHealth?.map((v) => v.id),
      projectScheduleHealthId: params.projectScheduleHealth?.map((v) => v.id),
      projectClientSatisfactionId: params.projectClientSatisfaction?.map((v) => v.id),
      projectTeamSatisfactionId: params.projectTeamSatisfaction?.map((v) => v.id),
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().projectHealthByProject(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const [drawer, setDrawer] = useState(null);

  const handleRowClick = ({ latestHealthReport }) => {
    setDrawer({ latestHealthReport });
  };

  const handleCloseDrawer = () => {
    setDrawer(null);
    documentTitle.set(report.name);
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .projectHealthByProject(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <>
      <Report>
        <Report.Header>
          <BackLink defaultPath={backLinkPath} ignoreHistory />

          <Report.Info>
            <Report.Eyebrow>Reports</Report.Eyebrow>
            <Report.Title report={report} />
          </Report.Info>

          <Report.Actions>
            <SavedReportActions report={report} onSave={() => searchParams.set(params)} />

            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>

            <FilterButton isOutline onClick={showFilters} />
          </Report.Actions>
        </Report.Header>

        <Report.FiltersBar>
          <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

          <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
        </Report.FiltersBar>

        {(() => {
          switch (query.status) {
            case 'loading':
            case 'filtering':
              return <PageLoader />;

            default:
              return (
                <>
                  <Data query={query} params={params} onSort={handleSort} onRowClick={handleRowClick} />
                  <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
                </>
              );
          }
        })()}
      </Report>

      {drawer && (
        <ProjectHealthReportForm
          id={drawer.latestHealthReport.id}
          projectId={drawer.latestHealthReport.projectId}
          onDeleted={fetchData}
          onSaved={fetchData}
          onClose={handleCloseDrawer}
        />
      )}
    </>
  );
}

function Data({ query, params, onSort, onRowClick }) {
  const report = query.data;

  return (
    <Report.Table>
      <Table>
        <Table.Header>
          <Table.Column sticky minWidth="16rem" name="project.name" onSort={onSort} sort={params.sort}>
            Project/Client
          </Table.Column>
          <Table.Column align="center" width="12rem" name="budgetHealthId" onSort={onSort} sort={params.sort}>
            Budget Health
          </Table.Column>
          <Table.Column align="center" width="12rem" name="scheduleHealthId" onSort={onSort} sort={params.sort}>
            Schedule Health
          </Table.Column>
          <Table.Column align="center" width="12rem" name="clientSatisfactionId" onSort={onSort} sort={params.sort}>
            Client Satisfaction
          </Table.Column>
          <Table.Column align="center" width="12rem" name="teamSatisfactionId" onSort={onSort} sort={params.sort}>
            Team Satisfaction
          </Table.Column>
          <Table.Column align="center" width="8rem" name="score" onSort={onSort} sort={params.sort}>
            Score
          </Table.Column>
          <Table.Column align="right" width="7.5rem" name="date" onSort={onSort} sort={params.sort}>
            Date
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.status === 'sorting'}>
          {report.records.map((project) => {
            const handleRowClick = () => onRowClick(project);

            return (
              <Table.Row key={project.id} onClick={handleRowClick}>
                <Table.Cell>
                  <ProjectInfo>
                    <p>
                      <ProjectLink project={project} tab="health" />
                    </p>
                    <small>
                      <ClientLink client={project.client} />
                      {project.client.isInternal && <InternalClientTooltip />}
                    </small>
                  </ProjectInfo>
                </Table.Cell>

                <Table.Cell>
                  <ProjectHealthCircle value={project.latestHealthReport.budgetHealth} />
                </Table.Cell>

                <Table.Cell>
                  <ProjectHealthCircle value={project.latestHealthReport.scheduleHealth} />
                </Table.Cell>

                <Table.Cell>
                  <ProjectHealthCircle value={project.latestHealthReport.clientSatisfaction} />
                </Table.Cell>

                <Table.Cell>
                  <ProjectHealthCircle value={project.latestHealthReport.teamSatisfaction} />
                </Table.Cell>

                <Table.Cell>
                  <ProjectHealthScoreBar score={project.latestHealthReport.score} />
                </Table.Cell>

                <Table.Cell>
                  <DateTime value={project.latestHealthReport.date} />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>

        <Table.Status
          total={report.records.length}
          label="Project"
          isLoading={query.status !== 'ready'}
          partial={report.partialResults}
        />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

export default ProjectHealthByProject;
