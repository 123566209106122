import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BackLink, Hours, Percent, RouteLink, Widget, ExportDropdown } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import MemberFiltersBar from '~/components/filters/MemberFiltersBar';
import MemberFiltersGroup from '~/components/filters/MemberFiltersGroup';
import Meter from '../components/Meter';
import { Metric, Metrics } from '../components/Metric';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter';
import Table from '../components/table';
import TaskFiltersBar from '~/components/filters/TaskFiltersBar';
import TaskFiltersGroup from '~/components/filters/TaskFiltersGroup';
import TimeFiltersBar from '~/components/filters/TimeFiltersBar';
import TimeFiltersGroup from '~/components/filters/TimeFiltersGroup';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import SavedReportActions from '../SavedReportActions';

const NoProjectType = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.grey25};
`;

function TimeSummaryByProjectType({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    projectTasks: [],
    projectTaskStatuses: [],
    projectTaskRecordStatusId: null,
    projectTaskTags: [],
    approvalStatuses: [],
    clientApprovalStatuses: [],
    invoiced: null,
    roleDisciplines: [],
    projectRoles: [],
    sort: new QuerySort('name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
    ...memberFilters.filters,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        projectTasks: searchParamsConfig.projectTasks,
        projectTaskStatuses: searchParamsConfig.projectTaskStatuses,
        projectTaskRecordStatusId: searchParamsConfig.recordStatusId,
        projectTaskTags: searchParamsConfig.projectTaskTags,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        invoiced: searchParamsConfig.invoiced,
        roleDisciplines: { ...searchParamsConfig.disciplines, key: 'roleDiscipline' },
        projectRoles: searchParamsConfig.projectRoles,
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      projectTaskId: params.projectTasks?.map((v) => v.id),
      projectTaskStatusId: params.projectTaskStatuses?.map((v) => v.id),
      projectTaskRecordStatusId: params.projectTaskRecordStatusId ?? undefined,
      projectTaskTagId: params.projectTaskTags?.map((v) => v.id),
      statusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      invoiced: params.invoiced ?? undefined,
      roleDisciplineId: params.roleDisciplines?.map((v) => v.id),
      projectRoleId: params.projectRoles?.map((v) => v.id),
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, features.clientApprovals, clientFilters, projectFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().timeSummaryByProjectType(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .timeSummaryByProjectType(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title report={report} />
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.pdf);
                    setIsOpen(false);
                  }}>
                  Export to PDF
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ReportPeriodFilter value={params.period} onChange={({ value }) => handleApplyFilters({ period: value })} />

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />

        <TaskFiltersBar filters={params} onChange={handleApplyFilters} />

        <TimeFiltersBar filters={params} onChange={handleApplyFilters} />

        <MemberFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;

  const { workspace } = useWorkspace();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time-entries?${new QueryString(
        {
          start: params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          projectRole: params.projectRoles?.map((v) => v.id),
          projectTask: params.projectTasks?.map((v) => v.id),
          projectTaskStatus: params.projectTaskStatuses?.map((v) => v.id),
          projectTaskRecordStatusId: params.projectTaskRecordStatusId ?? undefined,
          projectTaskTag: params.projectTaskTags?.map((v) => v.id),
          status: params.approvalStatuses?.map((v) => v.id),
          clientStatus: params.clientApprovalStatuses?.map((v) => v.id),
          invoiced: params.invoiced ?? undefined,
          roleDiscipline: params.roleDisciplines?.map((v) => v.id),
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...memberFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters, memberFilters],
  );

  return (
    <>
      <Report.Summary>
        <Widget>
          <Meter>
            <Meter.Value
              label="Client Billable"
              value={report.totals.billable}
              color={colors.success}
              tooltip={
                <>
                  <Hours value={report.totals.billable} /> hours (
                  <Percent value={report.totals.billable / report.totals.total} />)
                </>
              }
            />
            <Meter.Value
              label="Client Non-Billable"
              value={report.totals.nonBillable}
              color={colors.danger}
              tooltip={
                <>
                  <Hours value={report.totals.nonBillable} /> hours (
                  <Percent value={report.totals.nonBillable / report.totals.total} />)
                </>
              }
            />
            <Meter.Value
              label="Internal"
              value={report.totals.internal}
              color={colors.warning}
              tooltip={
                <>
                  <Hours value={report.totals.internal} /> hours (
                  <Percent value={report.totals.internal / report.totals.total} />)
                </>
              }
            />
          </Meter>

          <Metrics>
            <Metric>
              <RouteLink to={timeDetail({ billableType: 'billable' })}>
                <Metric.Value>
                  <Hours value={report.totals.billable} />
                </Metric.Value>
                <Metric.Label>Client Billable</Metric.Label>
              </RouteLink>
            </Metric>
            <Metric>
              <RouteLink to={timeDetail({ billableType: 'non_billable' })}>
                <Metric.Value>
                  <Hours value={report.totals.nonBillable} />
                </Metric.Value>
                <Metric.Label>Client Non-Billable</Metric.Label>
              </RouteLink>
            </Metric>
            <Metric>
              <RouteLink to={timeDetail({ billableType: 'internal' })}>
                <Metric.Value>
                  <Hours value={report.totals.internal} />
                </Metric.Value>
                <Metric.Label>Internal</Metric.Label>
              </RouteLink>
            </Metric>
            <Metric>
              <RouteLink to={timeDetail({ billableType: ['billable', 'non_billable', 'internal'] })}>
                <Metric.Value>
                  <Hours value={report.totals.total} />
                </Metric.Value>
                <Metric.Label>Total</Metric.Label>
              </RouteLink>
            </Metric>
          </Metrics>
        </Widget>
      </Report.Summary>

      <Report.Table>
        <Table>
          <Table.Header>
            <Table.Column sticky minWidth="16rem" name="name" onSort={onSort} sort={params.sort}>
              Project Type
            </Table.Column>
            <Table.Column name="billable" width="9rem" align="right" onSort={onSort} sort={params.sort}>
              Client
              <br />
              Billable
            </Table.Column>
            <Table.Column name="nonBillable" width="9rem" align="right" onSort={onSort} sort={params.sort}>
              Client
              <br />
              Non-Billable
            </Table.Column>
            <Table.Column name="internal" width="9rem" align="right" onSort={onSort} sort={params.sort}>
              Internal
            </Table.Column>
            <Table.Column name="total" width="9rem" align="right" onSort={onSort} sort={params.sort}>
              Total
            </Table.Column>
          </Table.Header>

          <Table.Body fade={query.status === 'sorting'}>
            {report.records.map((projectType) => {
              return (
                <Table.Row key={projectType.id || 'no_project_type'}>
                  <Table.Cell>
                    {projectType.id ? projectType.name : <NoProjectType>No Project Type</NoProjectType>}
                  </Table.Cell>
                  <Table.Cell>
                    {projectType.id ? (
                      <RouteLink to={timeDetail({ billableType: 'billable', projectType: projectType.id })}>
                        <Hours value={projectType.billable} />
                      </RouteLink>
                    ) : (
                      <Hours value={projectType.billable} />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {projectType.id ? (
                      <RouteLink to={timeDetail({ billableType: 'non_billable', projectType: projectType.id })}>
                        <Hours value={projectType.nonBillable} />
                      </RouteLink>
                    ) : (
                      <Hours value={projectType.nonBillable} />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {projectType.id ? (
                      <RouteLink to={timeDetail({ billableType: 'internal', projectType: projectType.id })}>
                        <Hours value={projectType.internal} />
                      </RouteLink>
                    ) : (
                      <Hours value={projectType.internal} />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {projectType.id ? (
                      <RouteLink to={timeDetail({ projectType: projectType.id })}>
                        <Hours value={projectType.total} />
                      </RouteLink>
                    ) : (
                      <Hours value={projectType.total} />
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell>
                <RouteLink to={timeDetail({ billableType: 'billable' })}>
                  <Hours value={report.totals.billable} />
                </RouteLink>
              </Table.Cell>
              <Table.Cell>
                <RouteLink to={timeDetail({ billableType: 'non_billable' })}>
                  <Hours value={report.totals.nonBillable} />
                </RouteLink>
              </Table.Cell>
              <Table.Cell>
                <RouteLink to={timeDetail({ billableType: 'internal' })}>
                  <Hours value={report.totals.internal} />
                </RouteLink>
              </Table.Cell>
              <Table.Cell>
                <RouteLink to={timeDetail({ billableType: ['billable', 'non_billable', 'internal'] })}>
                  <Hours value={report.totals.total} />
                </RouteLink>
              </Table.Cell>
            </Table.Row>
          </Table.Body>

          <Table.Status
            total={report.records.filter((p) => p.id).length}
            label="Project Type"
            isLoading={query.status !== 'ready'}
            partialResults={report.partialResults}
          />
        </Table>
      </Report.Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ReportPeriodFilter
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <TaskFiltersGroup filters={filters} onChange={handleFilter} />

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />

      <TimeFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

export default TimeSummaryByProjectType;
