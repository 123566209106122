import _ from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BackLink,
  ClientLink,
  Currency,
  DateTime,
  ProjectLink,
  RouteLink,
  Tag,
  Tooltip,
  ExportDropdown,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import invoiceStatuses from '~/lookups/invoice-statuses';
import QBOIndicator from '~/routes/app/invoices/components/QBOIndicator';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, intervalOptions } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import SentIndicator from '../../invoices/components/SentIndicator';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import CurrencyFilter from '~/components/filters/CurrencyFilter';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import InvoiceFiltersBar from '~/components/filters/InvoiceFiltersBar';
import InvoiceFiltersGroup from '~/components/filters/InvoiceFiltersGroup';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter';
import Table from '../components/table';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import SavedReportActions from '../SavedReportActions';

const Byline = styled.small`
  display: block;
`;

export default function Invoices({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const [params, setParams] = useState({
    period: null,
    invoiceServicesThroughPeriod: null,
    statuses: [invoiceStatuses.open],
    invoiceSent: null,
    currency: workspace.currency,
    sort: new QuerySort('project.name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { ...reportsSearchParamsConfig.period, default: intervalOptions.all_dates },
        invoiceServicesThroughPeriod: reportsSearchParamsConfig.invoiceServicesThroughPeriod,
        statuses: {
          multi: true,
          key: 'invoiceStatus',
          default: [invoiceStatuses.open],
          valid: ['all', ..._.map(invoiceStatuses, (v) => v.id)],
          serialize: function (value) {
            return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
          },
          deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => invoiceStatuses[id])),
        },
        invoiceSent: {
          valid: ['all', 'yes', 'no'],
          serialize: function (value) {
            return this.default && !value ? 'all' : value;
          },
          deserialize: (value) => (value === 'all' ? null : value),
        },
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('invoice.issuedOn', 'desc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      invoiceServicesThroughStart: params.invoiceServicesThroughPeriod?.start ?? undefined,
      invoiceServicesThroughEnd: params.invoiceServicesThroughPeriod?.end ?? undefined,
      statusId: params.statuses.map((v) => v.id),
      invoiceSent: params.invoiceSent ?? undefined,
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().invoicesList(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .invoicesList(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title report={report} />
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} data-testid="filters_button" />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ReportPeriodFilter
          value={params.period}
          onChange={({ target: { value } }) => handleApplyFilters({ period: value })}
        />

        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}
        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />
        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
        <InvoiceFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;
  const { workspace } = useWorkspace();

  return (
    <Report.Table>
      <Table>
        <Table.Header sticky>
          <Table.Column sticky width="12rem" name="invoice.transactionNumber" onSort={onSort} sort={params.sort}>
            #
          </Table.Column>
          <Table.Column name="client.name" minWidth="16rem" onSort={onSort} sort={params.sort}>
            Client
          </Table.Column>
          <Table.Column minWidth="16rem">Projects</Table.Column>
          <Table.Column width="8rem" name="invoice.issuedOn" onSort={onSort} sort={params.sort}>
            Issued
          </Table.Column>
          <Table.Column width="8rem" align="right" name="invoice.dueOn" onSort={onSort} sort={params.sort}>
            Due
          </Table.Column>
          <Table.Column width="6rem" name="actualStatusId" onSort={onSort} sort={params.sort}>
            Status
          </Table.Column>
          <Table.Column width="10rem" align="right" name="convertedBalance" onSort={onSort} sort={params.sort}>
            Balance
          </Table.Column>
          <Table.Column width="10rem" align="right" name="convertedTotal" onSort={onSort} sort={params.sort}>
            Total
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.loading.table}>
          {report.records.map((invoice) => {
            return (
              <Table.Row key={invoice.id}>
                <Table.Cell>
                  <RouteLink to={`/app/${workspace.key}/billing/invoices/${invoice.id}`}>{invoice.number}</RouteLink>
                </Table.Cell>
                <Table.Cell>
                  <ClientLink client={invoice.client} />
                </Table.Cell>
                <Table.Cell>
                  <FirstProject invoice={invoice} />
                  <Projects invoice={invoice} />
                </Table.Cell>
                <Table.Cell>
                  <DateTime value={invoice.issuedOn} />
                  {invoice?.sentAt && <SentIndicator sentAt={invoice.sentAt} />}
                </Table.Cell>
                <Table.Cell>
                  <div style={invoice.isLate ? { color: colors.danger } : undefined}>
                    <DateTime value={invoice.dueOn} />
                    {invoice.isLate && <p>({pluralize('day', invoice.daysLate, true)})</p>}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  {invoice.status.name}
                  {invoice.qboInvoiceId && <QBOIndicator />}
                </Table.Cell>
                <Table.Cell>
                  <p>
                    <Currency value={invoice.convertedBalance} currency={report.currency} />
                    {invoice.currency !== report.currency && (
                      <Byline>
                        <Currency value={invoice.balance} currency={invoice.currency} />
                      </Byline>
                    )}
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    <Currency value={invoice.convertedTotal} currency={report.currency} />
                    {invoice.currency !== report.currency && (
                      <Byline>
                        <Currency value={invoice.total} currency={invoice.currency} />
                      </Byline>
                    )}
                  </p>
                </Table.Cell>
              </Table.Row>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.balance} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.total} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>

        <Table.Status
          total={report.records.length}
          label="Invoice"
          isLoading={query.status !== 'ready'}
          partial={report.partialResults}
        />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ReportPeriodFilter
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <InvoiceFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

const FirstProject = ({ invoice }) => {
  const project = invoice.projects[0];
  if (!project) return null;

  return <ProjectLink project={project} client={invoice.client} />;
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Projects = ({ invoice }) => {
  let projectsCount = invoice.projects.length - 1; // Remove the first project because it already shows a tag
  if (projectsCount <= 0) return null;

  return (
    <Tooltip
      placement="right"
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Projects</Title>

          {invoice.projects.map((projects) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={projects.id}>
              <small>{projects.name}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40, cursor: 'default' }}>
        <small>+{projectsCount}</small>
      </Tag>
    </Tooltip>
  );
};
