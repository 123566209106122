import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BackLink,
  ClientLink,
  Currency,
  DateTime,
  Hours,
  InternalClientTooltip,
  Percent,
  ProjectLink,
  ExportDropdown,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import projectStatuses from '~/lookups/project-statuses';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import CurrencyFilter from '~/components/filters/CurrencyFilter';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import List from '../components/List.jsx';
import ListTooltip from '../components/ListTooltip.jsx';
import MultiCurrency from '../../../../components/MultiCurrency.jsx';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import Report from '../components/Report';
import Table from '../components/table';
import SavedReportActions from '../SavedReportActions';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

export default function PerformanceForecastByProject({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectRecordStatusId: {
      ...searchParamsConfig.recordStatusId,
      default: 'active',
    },
    projectStatuses: {
      ...searchParamsConfig.projectStatuses,
      default: [projectStatuses.not_started, projectStatuses.in_progress],
    },
  }));

  const [params, setParams] = useState({
    currency: workspace.currency,
    sort: new QuerySort('name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters, projectFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().performanceForecastByProject(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    setParams({ ...params, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .performanceForecastByProject(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title report={report} />
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />

          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>
      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;

  return (
    <Report.Table>
      <Table>
        <Table.Header>
          <Table.Column sticky name="name" minWidth="16rem" onSort={onSort} sort={params.sort}>
            Project/Client
          </Table.Column>
          <Table.Column width="7rem" name="statusId" onSort={onSort} sort={params.sort}>
            Status
          </Table.Column>
          <Table.Column width="8rem" align="right" name="start" onSort={onSort} sort={params.sort}>
            Start
          </Table.Column>
          <Table.Column width="8rem" align="right" name="end" onSort={onSort} sort={params.sort}>
            End
          </Table.Column>
          <Table.Column width="8rem" align="right" name="totalHours" onSort={onSort} sort={params.sort}>
            Hours
          </Table.Column>
          <Table.Column width="7.5rem" align="right" name="realizationRate" onSort={onSort} sort={params.sort}>
            Realization Rate
          </Table.Column>
          <Table.Column width="10rem" align="right" name="totalRevenue" onSort={onSort} sort={params.sort}>
            Total Revenue
          </Table.Column>
          <Table.Column width="7rem" align="right" name="effectiveRate" onSort={onSort} sort={params.sort}>
            Effective Bill Rate
          </Table.Column>
          <Table.Column width="10rem" align="right" name="cost" onSort={onSort} sort={params.sort}>
            Total Cost
          </Table.Column>
          <Table.Column width="10rem" align="right" name="profit" onSort={onSort} sort={params.sort}>
            Total Gross Profit
          </Table.Column>
          <Table.Column width="8rem" align="right" name="margin" onSort={onSort} sort={params.sort}>
            Total Gross Margin
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.loading.table}>
          {report.records.map((project) => {
            return (
              <Table.Row key={project.id}>
                <Table.Cell>
                  <ProjectInfo>
                    <div>
                      <ProjectLink project={project} />
                    </div>
                    <small>
                      <ClientLink client={project.client} />
                      {project.client.isInternal && <InternalClientTooltip />}
                    </small>
                  </ProjectInfo>
                </Table.Cell>
                <Table.Cell>{project.status.name}</Table.Cell>
                <Table.Cell>
                  <DateTime value={project.start} />
                </Table.Cell>
                <Table.Cell>
                  <DateTime value={project.end} />
                </Table.Cell>
                <Table.Cell>
                  <ListTooltip
                    message={
                      <List>
                        <List.Item>
                          <List.Heading>Past Actuals</List.Heading>
                          <List.Heading>Future Plan</List.Heading>
                          <List.Heading>Total Forecast</List.Heading>
                          {project.useBudget && <List.Heading>Budget</List.Heading>}
                        </List.Item>
                        <List.Item label="Client Billable Hours">
                          <Hours value={project.actualBillableHours} />
                          <Hours value={project.plannedBillableHours} />
                          <Hours value={project.billableHours} />
                          {project.useBudget && <Hours value={project.budgetBillableHours} />}
                        </List.Item>
                        <List.Item label="Client Non-Billable Hours">
                          <Hours value={project.actualNonBillableHours} />
                          <Hours value={project.plannedNonBillableHours} />
                          <Hours value={project.nonBillableHours} />
                          {project.useBudget && <Hours value={project.budgetNonBillableHours} />}
                        </List.Item>
                        <List.Item label="Internal Hours">
                          <Hours value={project.actualInternalHours} />
                          <Hours value={project.plannedInternalHours} />
                          <Hours value={project.internalHours} />
                          {project.useBudget && <Hours value={project.budgetInternalHours} />}
                        </List.Item>
                        <List.Item label="Total Hours">
                          <Hours value={project.actualTotalHours} />
                          <Hours value={project.plannedTotalHours} />
                          <Hours value={project.totalHours} />
                          {project.useBudget && <Hours value={project.budgetTotalHours} />}
                        </List.Item>
                      </List>
                    }>
                    <Hours value={project.totalHours} />
                  </ListTooltip>
                </Table.Cell>
                <Table.Cell>
                  <Percent value={project.realizationRate} />
                </Table.Cell>
                <Table.Cell>
                  <ListTooltip
                    style={{ flex: 1 }}
                    message={
                      <List>
                        <List.Item>
                          <List.Heading>Past Actuals</List.Heading>
                          <List.Heading>Future Plan</List.Heading>
                          <List.Heading>Total Forecast</List.Heading>
                          {project.useBudget && <List.Heading>Budget</List.Heading>}
                        </List.Item>
                        <List.Item label="Services Revenue">
                          <MultiCurrency
                            value={[
                              { value: project.convertedActualServicesRevenue, currency: report.currency },
                              { value: project.actualServicesRevenue, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedPlannedServicesRevenue, currency: report.currency },
                              { value: project.plannedServicesRevenue, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedServicesRevenue, currency: report.currency },
                              { value: project.servicesRevenue, currency: project.currency },
                            ]}
                          />
                          {project.useBudget && (
                            <MultiCurrency
                              value={[
                                { value: project.convertedBudgetServicesRevenue, currency: report.currency },
                                { value: project.budgetServicesRevenue, currency: project.currency },
                              ]}
                            />
                          )}
                        </List.Item>
                        <List.Item label="Expenses Revenue">
                          <MultiCurrency
                            value={[
                              { value: project.convertedActualExpensesRevenue, currency: report.currency },
                              { value: project.actualExpensesRevenue, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedPlannedExpensesRevenue, currency: report.currency },
                              { value: project.plannedExpensesRevenue, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedExpensesRevenue, currency: report.currency },
                              { value: project.expensesRevenue, currency: project.currency },
                            ]}
                          />
                          {project.useBudget && (
                            <MultiCurrency
                              value={[
                                { value: project.convertedBudgetExpensesRevenue, currency: report.currency },
                                { value: project.budgetExpensesRevenue, currency: project.currency },
                              ]}
                            />
                          )}
                        </List.Item>
                        <List.Item label="Other Items Revenue">
                          <MultiCurrency
                            value={[
                              { value: project.convertedActualOtherItemsRevenue, currency: report.currency },
                              { value: project.actualOtherItemsRevenue, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedPlannedOtherItemsRevenue, currency: report.currency },
                              { value: project.plannedOtherItemsRevenue, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedOtherItemsRevenue, currency: report.currency },
                              { value: project.otherItemsRevenue, currency: project.currency },
                            ]}
                          />
                          {project.useBudget && (
                            <MultiCurrency
                              value={[
                                { value: project.convertedBudgetOtherItemsRevenue, currency: report.currency },
                                { value: project.budgetOtherItemsRevenue, currency: project.currency },
                              ]}
                            />
                          )}
                        </List.Item>
                        <List.Item label="Total Revenue">
                          <MultiCurrency
                            value={[
                              { value: project.convertedActualTotalRevenue, currency: report.currency },
                              { value: project.actualTotalRevenue, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedPlannedTotalRevenue, currency: report.currency },
                              { value: project.plannedTotalRevenue, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedTotalRevenue, currency: report.currency },
                              { value: project.totalRevenue, currency: project.currency },
                            ]}
                          />
                          {project.useBudget && (
                            <MultiCurrency
                              value={[
                                { value: project.convertedBudgetTotalRevenue, currency: report.currency },
                                { value: project.budgetTotalRevenue, currency: project.currency },
                              ]}
                            />
                          )}
                        </List.Item>
                      </List>
                    }>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%' }}>
                      <div>
                        <MultiCurrency
                          value={[
                            { value: project.convertedTotalRevenue, currency: report.currency },
                            { value: project.totalRevenue, currency: project.currency },
                          ]}
                        />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%' }}>
                        <RevenueProgress actual={project.actualTotalRevenue} total={project.totalRevenue} />
                      </div>
                    </div>
                  </ListTooltip>
                </Table.Cell>
                <Table.Cell>
                  <MultiCurrency
                    value={[
                      { value: project.convertedEffectiveRate, currency: report.currency },
                      { value: project.effectiveRate, currency: project.currency },
                    ]}
                  />
                </Table.Cell>
                <Table.Cell>
                  <ListTooltip
                    message={
                      <List>
                        <List.Item>
                          <List.Heading>Past Actuals</List.Heading>
                          <List.Heading>Future Plan</List.Heading>
                          <List.Heading>Total Forecast</List.Heading>
                        </List.Item>
                        <List.Item label="Services Cost">
                          <MultiCurrency
                            value={[
                              { value: project.convertedActualLaborCost, currency: report.currency },
                              { value: project.actualLaborCost, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedPlannedLaborCost, currency: report.currency },
                              { value: project.plannedLaborCost, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedLaborCost, currency: report.currency },
                              { value: project.laborCost, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                        <List.Item label="Expenses Cost">
                          <MultiCurrency
                            value={[
                              { value: project.convertedActualExpensesCost, currency: report.currency },
                              { value: project.actualExpensesCost, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedPlannedExpensesCost, currency: report.currency },
                              { value: project.plannedExpensesCost, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedExpensesCost, currency: report.currency },
                              { value: project.expensesCost, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                        <List.Item label="Total Cost">
                          <MultiCurrency
                            value={[
                              { value: project.convertedActualCost, currency: report.currency },
                              { value: project.actualCost, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedPlannedCost, currency: report.currency },
                              { value: project.plannedCost, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedCost, currency: report.currency },
                              { value: project.cost, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                      </List>
                    }>
                    <MultiCurrency
                      value={[
                        { value: project.convertedCost, currency: report.currency },
                        { value: project.cost, currency: project.currency },
                      ]}
                    />
                  </ListTooltip>
                </Table.Cell>
                <Table.Cell>
                  <ListTooltip
                    message={
                      <List>
                        <List.Item>
                          <List.Heading>Past Actuals</List.Heading>
                          <List.Heading>Future Plan</List.Heading>
                          <List.Heading>Total Forecast</List.Heading>
                        </List.Item>
                        <List.Item label="Services Gross Profit">
                          <MultiCurrency
                            value={[
                              { value: project.convertedActualServicesProfit, currency: report.currency },
                              { value: project.actualServicesProfit, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedPlannedServicesProfit, currency: report.currency },
                              { value: project.plannedServicesProfit, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedServicesProfit, currency: report.currency },
                              { value: project.servicesProfit, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                        <List.Item label="Total Gross Profit">
                          <MultiCurrency
                            value={[
                              { value: project.convertedActualProfit, currency: report.currency },
                              { value: project.actualProfit, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedPlannedProfit, currency: report.currency },
                              { value: project.plannedProfit, currency: project.currency },
                            ]}
                          />
                          <MultiCurrency
                            value={[
                              { value: project.convertedProfit, currency: report.currency },
                              { value: project.profit, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                      </List>
                    }>
                    <MultiCurrency
                      value={[
                        { value: project.convertedProfit, currency: report.currency },
                        { value: project.profit, currency: project.currency },
                      ]}
                    />
                  </ListTooltip>
                </Table.Cell>
                <Table.Cell>
                  <ListTooltip
                    message={
                      <List>
                        <List.Item label="Services Gross Margin">
                          <Percent value={project.servicesMargin} />
                        </List.Item>
                      </List>
                    }>
                    <Percent value={project.margin} />
                  </ListTooltip>
                </Table.Cell>
              </Table.Row>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item>
                      <List.Heading>Past Actuals</List.Heading>
                      <List.Heading>Future Plan</List.Heading>
                      <List.Heading>Total Forecast</List.Heading>
                    </List.Item>
                    <List.Item label="Client Billable Hours">
                      <Hours value={report.totals.actualBillableHours} />
                      <Hours value={report.totals.plannedBillableHours} />
                      <Hours value={report.totals.billableHours} />
                    </List.Item>
                    <List.Item label="Client Non-Billable Hours">
                      <Hours value={report.totals.actualNonBillableHours} />
                      <Hours value={report.totals.plannedNonBillableHours} />
                      <Hours value={report.totals.nonBillableHours} />
                    </List.Item>
                    <List.Item label="Internal Hours">
                      <Hours value={report.totals.actualInternalHours} />
                      <Hours value={report.totals.plannedInternalHours} />
                      <Hours value={report.totals.internalHours} />
                    </List.Item>
                    <List.Item label="Total Hours">
                      <Hours value={report.totals.actualTotalHours} />
                      <Hours value={report.totals.plannedTotalHours} />
                      <Hours value={report.totals.totalHours} />
                    </List.Item>
                  </List>
                }>
                <Hours value={report.totals.totalHours} />
              </ListTooltip>
            </Table.Cell>
            <Table.Cell>
              <Percent value={report.totals.realizationRate} />
            </Table.Cell>
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item>
                      <List.Heading>Past Actuals</List.Heading>
                      <List.Heading>Future Plan</List.Heading>
                      <List.Heading>Total Forecast</List.Heading>
                    </List.Item>
                    <List.Item label="Services Revenue">
                      <Currency value={report.totals.actualServicesRevenue} currency={report.currency} />
                      <Currency value={report.totals.plannedServicesRevenue} currency={report.currency} />
                      <Currency value={report.totals.servicesRevenue} currency={report.currency} />
                    </List.Item>
                    <List.Item label="Other Items Revenue">
                      <Currency value={report.totals.actualOtherItemsRevenue} currency={report.currency} />
                      <Currency value={report.totals.plannedOtherItemsRevenue} currency={report.currency} />
                      <Currency value={report.totals.otherItemsRevenue} currency={report.currency} />
                    </List.Item>
                    <List.Item label="Expenses Revenue">
                      <Currency value={report.totals.actualExpensesRevenue} currency={report.currency} />
                      <Currency value={report.totals.plannedExpensesRevenue} currency={report.currency} />
                      <Currency value={report.totals.expensesRevenue} currency={report.currency} />
                    </List.Item>
                    <List.Item label="Total Revenue">
                      <Currency value={report.totals.actualTotalRevenue} currency={report.currency} />
                      <Currency value={report.totals.plannedTotalRevenue} currency={report.currency} />
                      <Currency value={report.totals.totalRevenue} currency={report.currency} />
                    </List.Item>
                  </List>
                }>
                <Currency value={report.totals.totalRevenue} currency={report.currency} />
              </ListTooltip>
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.effectiveRate} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item>
                      <List.Heading>Past Actuals</List.Heading>
                      <List.Heading>Future Plan</List.Heading>
                      <List.Heading>Total Forecast</List.Heading>
                    </List.Item>
                    <List.Item label="Services Cost">
                      <Currency value={report.totals.actualLaborCost} currency={report.currency} />
                      <Currency value={report.totals.plannedLaborCost} currency={report.currency} />
                      <Currency value={report.totals.laborCost} currency={report.currency} />
                    </List.Item>
                    <List.Item label="Expenses Cost">
                      <Currency value={report.totals.actualExpensesCost} currency={report.currency} />
                      <Currency value={report.totals.plannedExpensesCost} currency={report.currency} />
                      <Currency value={report.totals.expensesCost} currency={report.currency} />
                    </List.Item>
                    <List.Item label="Total">
                      <Currency value={report.totals.actualCost} currency={report.currency} />
                      <Currency value={report.totals.plannedCost} currency={report.currency} />
                      <Currency value={report.totals.cost} currency={report.currency} />
                    </List.Item>
                  </List>
                }>
                <Currency value={report.totals.cost} currency={report.currency} />
              </ListTooltip>
            </Table.Cell>
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item>
                      <List.Heading>Past Actuals</List.Heading>
                      <List.Heading>Future Plan</List.Heading>
                      <List.Heading>Total Forecast</List.Heading>
                    </List.Item>
                    <List.Item label="Services Gross Profit">
                      <Currency value={report.totals.actualServicesProfit} currency={report.currency} />
                      <Currency value={report.totals.plannedServicesProfit} currency={report.currency} />
                      <Currency value={report.totals.servicesProfit} currency={report.currency} />
                    </List.Item>
                    <List.Item label="Total Gross Profit">
                      <Currency value={report.totals.actualProfit} currency={report.currency} />
                      <Currency value={report.totals.plannedProfit} currency={report.currency} />
                      <Currency value={report.totals.profit} currency={report.currency} />
                    </List.Item>
                  </List>
                }>
                <Currency value={report.totals.profit} currency={report.currency} />
              </ListTooltip>
            </Table.Cell>
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item label="Services Gross Margin">
                      <Percent value={report.totals.servicesMargin} />
                    </List.Item>
                  </List>
                }>
                <Percent value={report.totals.margin} />
              </ListTooltip>
            </Table.Cell>
          </Table.Row>
        </Table.Body>

        <Table.Status
          total={report.records.length}
          label="Project"
          isLoading={query.status !== 'ready'}
          partial={report.partialResults}
        />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

const ProgressContainer = styled.div`
  font-size: 0.75rem;
  flex: 1;
  width: 100%;
`;

const ProgressLine = styled.div`
  display: block;
  width: ${({ width }) => width ?? '100%'};
  height: 0.5rem;
  background-color: ${({ color }) => color ?? colors.grey5};
  border-radius: 999rem;
  overflow: hidden;
  transition: width 0.5s ease-out;
`;

const Progress = styled(ProgressLine)`
  margin: 0.25rem 0;
`;

function RevenueProgress({ actual = 0, total = null }) {
  const percent = (total && total > 0 ? actual / total : actual > 0 ? 1 : 0) * 100;

  return (
    <ProgressContainer>
      <Progress>
        <ProgressLine width={`${Math.min(percent, 100)}%`} color={colors.primary} />
      </Progress>
    </ProgressContainer>
  );
}
