import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BackLink,
  ClientLink,
  Currency,
  Hours,
  InternalClientTooltip,
  MemberFilter,
  Percent,
  ProjectLink,
  RouteLink,
  ExportDropdown,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import CurrencyFilter from '~/components/filters/CurrencyFilter';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import FiltersGroup from '~/components/filters/FiltersGroup';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter';
import TaskFiltersBar from '~/components/filters/TaskFiltersBar';
import TaskFiltersGroup from '~/components/filters/TaskFiltersGroup';
import Table from '../components/table';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import SavedReportActions from '../SavedReportActions';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const Task = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const NoTask = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  color: ${colors.grey25};
`;

const Byline = styled.small`
  display: block;
`;

export default function PerformanceByProjectAndTask({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const [params, setParams] = useState({
    period: null,
    currency: workspace.currency,
    sort: new QuerySort('name', 'asc'),
    projectTasks: [],
    projectTaskStatuses: [],
    projectTaskRecordStatusId: null,
    projectTaskTags: [],
    excludeMembers: [],
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  // Init and sync URL search params
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');

  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('project.name', 'asc'), ...searchParamsConfig.sort },
        projectTasks: searchParamsConfig.projectTasks,
        projectTaskStatuses: searchParamsConfig.projectTaskStatuses,
        projectTaskRecordStatusId: searchParamsConfig.recordStatusId,
        projectTaskTags: searchParamsConfig.projectTaskTags,
        excludeMembers: searchParamsConfig.excludeMembers,
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      currency: params.currency ?? undefined,
      sort: params.sort,
      projectTaskId: params.projectTasks?.map((v) => v.id),
      projectTaskStatusId: params.projectTaskStatuses?.map((v) => v.id),
      projectTaskRecordStatusId: params.projectTaskRecordStatusId ?? undefined,
      projectTaskTagId: params.projectTaskTags?.map((v) => v.id),
      excludeMemberId: params.excludeMembers?.map((v) => v.id),
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().performanceByProjectAndTask(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .performanceByProjectAndTask(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title report={report} />
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />

          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ReportPeriodFilter
          clearable={false}
          value={params.period}
          onChange={({ target: { value } }) => handleApplyFilters({ period: value })}
        />
        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}
        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />
        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
        <TaskFiltersBar filters={params} onChange={handleApplyFilters} />
        {!_.isEmpty(params.excludeMembers) && (
          <MemberFilter
            materialPlaceholder="Exclude Member"
            value={params.excludeMembers}
            onChange={({ value }) => handleApplyFilters({ excludeMembers: value })}
          />
        )}
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;
  const { workspace } = useWorkspace();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time-entries?${new QueryString(
        {
          start: params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          status: params.approvalStatuses?.map((v) => v.id),
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters],
  );

  const { projects, tasksByProjectId } = useMemo(() => {
    return {
      projects: report.projects,
      tasksByProjectId: _.groupBy(report.tasks, 'projectId'),
    };
  }, [report.projects, report.tasks]);

  return (
    <Report.Table>
      <Table>
        <Table.Header>
          <Table.Column sticky name="project.name" minWidth="16rem" onSort={onSort} sort={params.sort}>
            Project/Client
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Hours
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Services Revenue
          </Table.Column>
          <Table.Column width="7rem" align="right">
            Effective Bill Rate
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Services Cost
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Services Profit
          </Table.Column>
          <Table.Column width="6rem" align="right">
            Services Margin
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.status === 'sorting'}>
          {projects.map((project) => {
            return (
              <React.Fragment key={project.id}>
                <Table.GroupRow top="4.075rem">
                  <Table.Cell>
                    <ProjectInfo>
                      <p>
                        <ProjectLink project={project} />
                      </p>
                      <small>
                        <ClientLink client={project.client} />
                        {project.client.isInternal && <InternalClientTooltip />}
                      </small>
                    </ProjectInfo>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <RouteLink to={timeDetail({ project: project.id })}>
                        <Hours value={project.totalHours} />
                      </RouteLink>
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <Currency value={project.convertedServicesRevenue} currency={report.currency} />
                      {project.currency !== report.currency && (
                        <Byline>
                          <Currency value={project.servicesRevenue} currency={project.currency} />
                        </Byline>
                      )}
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <Currency value={project.convertedEffectiveRate} currency={report.currency} />
                      {project.currency !== report.currency && (
                        <Byline>
                          <Currency value={project.effectiveRate} currency={project.currency} />
                        </Byline>
                      )}
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <Currency value={project.convertedServicesCost} currency={report.currency} />
                      {project.currency !== report.currency && (
                        <Byline>
                          <Currency value={project.servicesCost} currency={project.currency} />
                        </Byline>
                      )}
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <Currency value={project.convertedServicesProfit} currency={report.currency} />
                      {project.currency !== report.currency && (
                        <Byline>
                          <Currency value={project.servicesProfit} currency={project.currency} />
                        </Byline>
                      )}
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <Percent value={project.servicesMargin} />
                    </strong>
                  </Table.Cell>
                </Table.GroupRow>

                {tasksByProjectId[project.id]?.map((task) => {
                  return (
                    <Table.Row key={task.id}>
                      <Table.Cell>{task.id ? <Task>{task.name}</Task> : <NoTask>{task.name}</NoTask>}</Table.Cell>
                      <Table.Cell>
                        {task.id ? (
                          <RouteLink to={timeDetail({ project: project.id, projectTask: task.id })}>
                            <Hours value={task.totalHours} />
                          </RouteLink>
                        ) : (
                          <Hours value={task.totalHours} />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <p>
                          <Currency value={task.convertedServicesRevenue} currency={report.currency} />
                          {project.currency !== report.currency && (
                            <Byline>
                              <Currency value={task.servicesRevenue} currency={project.currency} />
                            </Byline>
                          )}
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>
                          <Currency value={task.convertedEffectiveRate} currency={report.currency} />
                          {project.currency !== report.currency && (
                            <Byline>
                              <Currency value={task.effectiveRate} currency={project.currency} />
                            </Byline>
                          )}
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>
                          <Currency value={task.convertedServicesCost} currency={report.currency} />
                          {project.currency !== report.currency && (
                            <Byline>
                              <Currency value={task.servicesCost} currency={project.currency} />
                            </Byline>
                          )}
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>
                          <Currency value={task.convertedServicesProfit} currency={report.currency} />
                          {project.currency !== report.currency && (
                            <Byline>
                              <Currency value={task.servicesProfit} currency={project.currency} />
                            </Byline>
                          )}
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <Percent value={task.servicesMargin} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })}
        </Table.Body>

        <Table.Status
          total={report.projects.length}
          label="Project"
          isLoading={query.status !== 'ready'}
          partial={report.partialResults}
        />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ReportPeriodFilter
        clearable={false}
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <TaskFiltersGroup filters={filters} onChange={handleFilter} />

      {/* TODO: this filter/section is non-standard and should be reconsidered */}
      <FiltersGroup label="Time Filters" filters={[filters.excludeMembers]}>
        <MemberFilter
          value={filters.excludeMembers}
          placeholder="Exclude Member"
          onChange={({ target: { value } }) => handleFilter({ excludeMembers: value })}
        />
      </FiltersGroup>
    </Report.FiltersDrawer>
  );
}
