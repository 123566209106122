import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BackLink, Hours, RouteLink, ExportDropdown } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useMemberFilters from '~/hooks/useMemberFilters';
import { PageLoader } from '~/routes/public/pages';
import { weights } from '~/styles';
import { QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import MemberFiltersBar from '~/components/filters/MemberFiltersBar';
import MemberFiltersGroup from '~/components/filters/MemberFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter';
import Table from '../components/table';
import TimeFiltersBar from '~/components/filters/TimeFiltersBar';
import TimeFiltersGroup from '~/components/filters/TimeFiltersGroup';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import SavedReportActions from '../SavedReportActions';

const Member = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${weights.bold};
`;

const Type = styled.div`
  margin-left: 1rem;
`;

export default function TimeOffByMember({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    approvalStatuses: [],
    timeOffTypes: [],
    sort: new QuerySort('member.name', 'asc'),
    ...memberFilters.filters,
  });

  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        timeOffTypes: searchParamsConfig.timeOffTypes,
        sort: { default: new QuerySort('member.name', 'asc'), ...searchParamsConfig.sort },
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, memberFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      approvalStatusId: params.approvalStatuses?.map((v) => v.id),
      timeOffTypeId: params.timeOffTypes?.map((v) => v.id),
      sort: params.sort,
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().timeOffByMember(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .timeOffByMember(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title report={report} />
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.pdf);
                    setIsOpen(false);
                  }}>
                  Export to PDF
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ReportPeriodFilter value={params.period} onChange={({ value }) => handleApplyFilters({ period: value })} />

        <TimeFiltersBar filters={params} onChange={handleApplyFilters} />

        <MemberFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;
  const { workspace } = useWorkspace();

  const groups = useMemo(() => {
    return _.groupBy(report.records, (record) => record.member.id);
  }, [report.records]);

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time-entries?${new QueryString(
        {
          start: params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          status: params.approvalStatuses?.map((v) => v.id),
          timeOffType: params.timeOffTypes?.map((v) => v.id),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key],
  );

  return (
    <Report.Table>
      <Table>
        <Table.Header>
          <Table.Column sticky minWidth="16rem" name="member.name" onSort={onSort} sort={params.sort}>
            Member
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Hours
          </Table.Column>
        </Table.Header>
        <Table.Body fade={query.status === 'sorting'}>
          {_.map(groups, (timeEntries) => {
            const member = timeEntries[0].member;

            const totalHours = _.sum(timeEntries.map((timeEntry) => timeEntry.sum));

            return (
              <React.Fragment key={member.id}>
                <Table.GroupRow top="3rem">
                  <Table.Cell>
                    <Member>{member.name}</Member>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <RouteLink to={timeDetail({ member: member.id, billableType: 'time_off' })}>
                        <Hours value={totalHours} />
                      </RouteLink>
                    </strong>
                  </Table.Cell>
                </Table.GroupRow>
                {timeEntries.map((record) => {
                  return (
                    <Table.Row key={record.timeOffType ? record.timeOffType.id : 'no_time_off'}>
                      <Table.Cell>
                        <Type>{record.timeOffType?.name}</Type>
                      </Table.Cell>
                      <Table.Cell>
                        <RouteLink
                          to={timeDetail({
                            member: member.id,
                            timeOffType: record.timeOffType?.id,
                          })}>
                          <Hours value={record.sum} />
                        </RouteLink>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })}
          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>
              <Hours value={report.totals.total} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>

        <Table.Status
          total={report.records.length}
          label="Member"
          isLoading={query.status !== 'ready'}
          partial={report.partialResults}
        />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ReportPeriodFilter
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      <TimeFiltersGroup filters={filters} onChange={handleFilter} />

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}
