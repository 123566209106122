import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Avatar,
  BackLink,
  Currency,
  DateTime,
  MemberContactPopover,
  OpportunityLink,
  Percent,
  Tag,
  Tooltip,
  ExportDropdown,
} from '~/components';
import CompanyLink from '~/components/CompanyLink';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useIsMounted, useSearchParams, useSearchParamsConfig } from '~/hooks';
import opportunityStageCategories from '~/lookups/opportunity-stage-categories';
import PageLoader from '~/routes/public/pages/PageLoader';
import { colors, weights } from '~/styles';
import { QuerySort, mimeTypes } from '~/utils';
import CompanyFiltersBar from '~/components/filters/CompanyFiltersBar';
import CompanyFiltersGroup from '~/components/filters/CompanyFiltersGroup';
import CurrencyFilter from '~/components/filters/CurrencyFilter';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import OpportunityFiltersBar from '~/components/filters/OpportunityFiltersBar';
import OpportunityFiltersGroup from '~/components/filters/OpportunityFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter';
import Table from '../components/table';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import SavedReportActions from '../SavedReportActions';

const Byline = styled.small`
  display: block;
`;

const InfoContainer = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const Owner = styled.div`
  display: flex;
  align-items: center;
`;

const OwnerInfo = styled.span`
  margin-left: 0.5rem;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TagsTitle = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

function FirstAssignment({ tags }) {
  const firstTag = tags[0];
  if (!firstTag) return null;

  return (
    <Tag style={{ backgroundColor: colors.grey5 }}>
      <small>{firstTag.name}</small>
    </Tag>
  );
}

function Assignments({ tags }) {
  const count = tags.length - 1;
  if (count <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <TagsTitle>Tags</TagsTitle>
          {tags.map((tag) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={tag.id}>
              <small>{tag.name}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{count}</small>
      </Tag>
    </Tooltip>
  );
}

function OpportunityDetail({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();
  const isMounted = useIsMounted();

  const [query, setQuery] = useState({ report: null, status: 'loading', loading: { table: false } });
  const [params, setParams] = useState({
    createdPeriod: null,
    closedPeriod: null,
    company: [],
    opportunityStageCategoryId: [],
    opportunityStages: [],
    opportunityOwner: [],
    opportunityTags: [],
    opportunityType: [],
    opportunityAmountGreaterThanOrEqual: null,
    opportunityPractice: [],
    currency: null,
    sort: new QuerySort('closeDate', 'asc'),
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        createdPeriod: reportsSearchParamsConfig.createdPeriod,
        closedPeriod: reportsSearchParamsConfig.closedPeriod,
        company: searchParamsConfig.companies,
        opportunityStageCategoryId: searchParamsConfig.opportunityStageCategories,
        opportunityStages: searchParamsConfig.opportunityStages,
        opportunityOwner: searchParamsConfig.members,
        opportunityTags: searchParamsConfig.opportunityTags,
        opportunityType: searchParamsConfig.opportunityTypes,
        opportunityAmountGreaterThanOrEqual: {
          ...searchParamsConfig.amount,
          key: 'opportunityAmountGreaterThanOrEqual',
        },
        opportunityPractice: searchParamsConfig.practices,
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('closeDate', 'asc'), ...searchParamsConfig.sort },
      }),
      [searchParamsConfig, reportsSearchParamsConfig],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      createdStart: params.createdPeriod?.start ?? undefined,
      createdEnd: params.createdPeriod?.end ?? undefined,
      closedStart: params.closedPeriod?.start ?? undefined,
      closedEnd: params.closedPeriod?.end ?? undefined,
      companyId: params.company.map((v) => v.id),
      opportunityStageCategoryId: params.opportunityStageCategoryId.map((v) => v.id),
      opportunityStageIds: params.opportunityStages.length ? params.opportunityStages.map((v) => v.id) : [],
      opportunityOwnerId: params.opportunityOwner.map((v) => v.id),
      opportunityTagId: params.opportunityTags.map((v) => v.id),
      opportunityTypeId: params.opportunityType.map((v) => v.id),
      opportunityAmountGreaterThanOrEqual: params.opportunityAmountGreaterThanOrEqual ?? undefined,
      opportunityPracticeId: params.opportunityPractice.map((v) => v.id),
      currency: params.currency ?? undefined,
      sort: params.sort,
    }),
    [params],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().opportunityDetail(urlSearchParams);
    if (!isMounted.current) return;

    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: {
        table: false,
      },
    }));
  }, [api, workspace.id, urlSearchParams, isMounted]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .opportunityDetail(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title report={report} />
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />

          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        {(params.createdPeriod?.start || params.createdPeriod?.end) && (
          <ReportPeriodFilter
            value={params.createdPeriod}
            label="Created Date Range"
            onChange={({ target: { value: createdPeriod } }) => handleApplyFilters({ createdPeriod })}
          />
        )}

        {(params.closedPeriod?.start || params.closedPeriod?.end) && (
          <ReportPeriodFilter
            value={params.closedPeriod}
            label="Expected Close Date Range"
            onChange={({ target: { value } }) => handleApplyFilters({ closedPeriod: value })}
          />
        )}

        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}

        <CompanyFiltersBar filters={params} onChange={handleApplyFilters} />

        <OpportunityFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;

  return (
    <Report.Table>
      <Table>
        <Table.Header sticky>
          <Table.Column sticky name="opportunity.name" onSort={onSort} sort={params.sort} minWidth="16rem">
            Opportunity/Company
          </Table.Column>
          <Table.Column name="owner.name" onSort={onSort} sort={params.sort} minWidth="14rem">
            Owner
          </Table.Column>
          <Table.Column name="opportunityStage.name" onSort={onSort} sort={params.sort} width="12rem">
            Stage
          </Table.Column>
          <Table.Column minWidth="10rem">Tags</Table.Column>
          <Table.Column name="convertedAmount" onSort={onSort} sort={params.sort} align="right" width="10rem">
            Amount
          </Table.Column>
          <Table.Column name="probability" onSort={onSort} sort={params.sort} align="right" width="8rem">
            Probability
          </Table.Column>
          <Table.Column name="convertedWeightedAmount" onSort={onSort} sort={params.sort} align="right" width="10rem">
            Weighted
          </Table.Column>
          <Table.Column name="lastActivityDate" onSort={onSort} sort={params.sort} align="right" width="7rem">
            Last Activity
          </Table.Column>
          <Table.Column name="createdAt" onSort={onSort} sort={params.sort} align="right" width="7rem">
            Created
          </Table.Column>
          <Table.Column name="closeDate" onSort={onSort} sort={params.sort} align="right" width="7rem">
            Closing
          </Table.Column>
        </Table.Header>

        <Table.Body>
          {report.records.map((opportunity) => (
            <Table.Row key={opportunity.id}>
              <Table.Cell>
                <InfoContainer>
                  <OpportunityLink opportunity={opportunity} />
                  <small>
                    <CompanyLink company={opportunity.company} />
                  </small>
                </InfoContainer>
              </Table.Cell>
              <Table.Cell>
                {opportunity.owner && (
                  <Owner>
                    <MemberContactPopover member={opportunity.owner} placement="left">
                      <Avatar value={opportunity.owner} isCircle hasBackground initialsFontSize=".9rem" />
                    </MemberContactPopover>
                    <OwnerInfo>{opportunity.owner.name}</OwnerInfo>
                  </Owner>
                )}
              </Table.Cell>
              <Table.Cell>
                <p>
                  {`${opportunity.opportunityStage.name}`}
                  <Byline>{opportunityStageCategories[opportunity.opportunityStage.statusId].name}</Byline>
                </p>
              </Table.Cell>
              <Table.Cell>
                <Tags>
                  <FirstAssignment tags={opportunity.tags} />
                  <Assignments tags={opportunity.tags} />
                </Tags>
              </Table.Cell>
              <Table.Cell>
                <p>
                  <Currency value={opportunity.convertedAmount} currency={report.currency} />
                  {opportunity.currency !== report.currency && (
                    <Byline>
                      <Currency value={opportunity.amount} currency={opportunity.currency} />
                    </Byline>
                  )}
                </p>
              </Table.Cell>
              <Table.Cell>
                <Percent value={opportunity.probability / 100} minimumFractionDigits={0} maximumFractionDigits={0} />
              </Table.Cell>
              <Table.Cell>
                <p>
                  <Currency value={opportunity.convertedWeightedAmount} currency={report.currency} />
                  {opportunity.currency !== report.currency && (
                    <Byline>
                      <Currency value={opportunity.weightedAmount} currency={opportunity.currency} />
                    </Byline>
                  )}
                </p>
              </Table.Cell>
              <Table.Cell>
                <DateTime value={opportunity.lastActivityDate} />
              </Table.Cell>
              <Table.Cell>
                <DateTime value={opportunity.createdAt} />
              </Table.Cell>
              <Table.Cell>
                <DateTime value={opportunity.closeDate} />
              </Table.Cell>
            </Table.Row>
          ))}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell>
              <Currency value={report.totals.convertedAmount} currency={report.currency} />
            </Table.Cell>
            <Table.Cell />
            <Table.Cell>
              <Currency value={report.totals.convertedWeightedAmount} currency={report.currency} />
            </Table.Cell>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
          </Table.Row>
        </Table.Body>

        <Table.Status
          total={report.records.length}
          label="Opportunity"
          isLoading={query.status !== 'ready'}
          partial={report.partialResults}
        />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ReportPeriodFilter
        value={filters.createdPeriod}
        label="Created Date Range"
        onChange={({ target: { value: createdPeriod } }) => handleFilter({ createdPeriod })}
      />

      <CompanyFiltersGroup filters={filters} onChange={handleFilter} />

      <OpportunityFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

export default OpportunityDetail;
