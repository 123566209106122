import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BackLink,
  BillableIcon,
  DateTime,
  ExportDropdown,
  Hours,
  Percent,
  Tag,
  TimeLockIcon,
  Tooltip,
  Widget,
  YesNoFilter,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useIsMounted, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import timeBillableTypes from '~/lookups/time-billable-types.js';
import EditTimeEntry from '~/routes/app/time/edit-time-entry';
import ViewTimeEntry from '~/routes/app/time/view-time-entry';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import CurrencyFilter from '~/components/filters/CurrencyFilter';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import MemberFiltersBar from '~/components/filters/MemberFiltersBar';
import MemberFiltersGroup from '~/components/filters/MemberFiltersGroup';
import Meter from '../components/Meter';
import { Metric, Metrics } from '../components/Metric';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter';
import Table from '../components/table';
import TaskFiltersBar from '~/components/filters/TaskFiltersBar';
import TaskFiltersGroup from '~/components/filters/TaskFiltersGroup';
import TimeFiltersBar from '~/components/filters/TimeFiltersBar';
import TimeFiltersGroup from '~/components/filters/TimeFiltersGroup';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import SavedReportActions from '../SavedReportActions';

const Byline = styled.small`
  display: block;
`;

const TimeEntryNotes = styled.p`
  flex-basis: 100%;
  padding: 0 4rem 1rem 12rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;

const FirstApprover = ({ entry }) => {
  if (!entry.approvers) return null;
  const approver = entry.approvers[0];

  return approver;
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Approvers = ({ entry }) => {
  let approversCount = entry.approvers?.length - 1; // Remove the first approver because it already shows a tag
  if (approversCount <= 0 || !approversCount) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Approvers</Title>

          {entry.approvers.map((approver) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={approver}>
              <small>{approver}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{approversCount}</small>
      </Tag>
    </Tooltip>
  );
};

function TimeEntriesPendingApproval({ report, backLinkPath }) {
  const documentTitle = useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const isMounted = useIsMounted();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [query, setQuery] = useState({
    report: null,
    params: {
      period: null,
      createdPeriod: null,
      modifiedPeriod: null,
      projectTasks: [],
      projectTaskStatuses: [],
      projectTaskRecordStatusId: null,
      projectTaskTags: [],
      clientApprovalStatuses: [],
      billableTypes: [],
      timeOffTypes: [],
      invoiced: null,
      roleDisciplines: [],
      projectRoles: [],
      currency: workspace.currency,
      sort: new QuerySort('date', 'asc'),
      page: 0,
      size: 50,
      ...clientFilters.filters,
      ...projectFilters.filters,
      ...memberFilters.filters,
    },
    action: 'load',
    status: 'loading',
    loading: {
      summary: false,
      table: false,
    },
  });

  const params = query.params;

  const setParams = (params) => {
    setQuery((state) => ({
      ...state,
      params: { ...state.params, ...params },
    }));
  };

  const loadMore = () => {
    setQuery((state) => {
      if (
        state.status !== 'ready' ||
        state.action !== null ||
        !state.report ||
        state.report.records.total <= state.report.records.results.length
      )
        return state;

      return {
        ...state,
        params: { ...state.params, page: state.params.page + 1 },
        action: 'load-more',
      };
    });
  };

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        createdPeriod: reportsSearchParamsConfig.createdPeriod,
        modifiedPeriod: reportsSearchParamsConfig.modifiedPeriod,
        projectTasks: searchParamsConfig.projectTasks,
        projectTaskStatuses: searchParamsConfig.projectTaskStatuses,
        projectTaskRecordStatusId: searchParamsConfig.recordStatusId,
        projectTaskTags: searchParamsConfig.projectTaskTags,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        billableTypes: searchParamsConfig.timeBillableTypes,
        timeOffTypes: searchParamsConfig.timeOffTypes,
        invoiced: searchParamsConfig.invoiced,
        roleDisciplines: { ...searchParamsConfig.disciplines, key: 'roleDiscipline' },
        projectRoles: searchParamsConfig.projectRoles,
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('date', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),

    onChange: (params) => setParams(params),
  });

  const urlSearchParams = useMemo(() => {
    return {
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      createdStart: params.createdPeriod?.start ?? undefined,
      createdEnd: params.createdPeriod?.end ?? undefined,
      modifiedStart: params.modifiedPeriod?.start ?? undefined,
      modifiedEnd: params.modifiedPeriod?.end ?? undefined,
      projectTaskId: params.projectTasks?.map((v) => v.id),
      projectTaskStatusId: params.projectTaskStatuses?.map((v) => v.id),
      projectTaskRecordStatusId: params.projectTaskRecordStatusId ?? undefined,
      projectTaskTagId: params.projectTaskTags?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      billableTypeId: params.billableTypes?.map((v) => v.id),
      timeOffTypeId: params.timeOffTypes?.map((v) => v.id),
      invoiced: params.invoiced ?? undefined,
      roleDisciplineId: params.roleDisciplines?.map((v) => v.id),
      projectRoleId: params.projectRoles?.map((v) => v.id),
      currency: params.currency ?? undefined,
      sort: params.sort,
      page: params.page,
      size: params.size,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
    };
  }, [params, features.clientApprovals, clientFilters, projectFilters, memberFilters]);

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams(params);
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().timeEntriesPendingApproval(urlSearchParams);

    if (!isMounted.current) return;

    setQuery((state) => ({
      ...state,
      action: null,
      status: 'ready',
      loading: { summary: false, table: false },
      report: {
        ...data,
        records: {
          ...data.records,
          results:
            state.action === 'load-more'
              ? [...state.report.records.results, ...data.records.results]
              : data.records.results,
        },
      },
    }));
  }, [api, workspace.id, urlSearchParams, isMounted]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [showNotes, setShowNotes] = useState(false);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values, page: 0 });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, page: 0, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const [drawer, setDrawer] = useState(null);

  const handleRowClick = (entry) => {
    setDrawer({ entry, mode: entry.isLocked ? 'view' : 'edit' });
  };

  const handleCloseDrawer = () => {
    setDrawer(null);
    documentTitle.set('Time Entries Pending Approval');
  };

  const handleRowSaved = async (savedTimeEntry) => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .reports()
      .timeEntriesPendingApproval({
        ...urlSearchParams,
        timeEntryId: savedTimeEntry.id,
      });

    if (!isMounted.current) return;

    const entry = data.records.find((record) => record.id === savedTimeEntry.id);

    setQuery((state) => ({
      ...state,
      report: {
        ...data,
        records: {
          total: entry ? state.report.records.total : state.report.records.total - 1,
          results: entry
            ? state.report.records.results.map((result) => (result.id === savedTimeEntry.id ? entry : result))
            : state.report.records.results.filter((result) => result.id !== savedTimeEntry.id),
        },
      },
    }));
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .timeEntriesPendingApproval(
            { ...urlSearchParams, page: undefined, size: null, showNotes },
            { headers: { accept: mimeType } },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <>
      <Report>
        <Report.Header>
          <BackLink defaultPath={backLinkPath} ignoreHistory />

          <Report.Info>
            <Report.Eyebrow>Reports</Report.Eyebrow>
            <Report.Title report={report} />
          </Report.Info>

          <Report.Actions>
            <SavedReportActions report={report} onSave={() => searchParams.set(params)} />
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.pdf);
                      setIsOpen(false);
                    }}>
                    Export to PDF
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>

            <FilterButton isOutline onClick={showFilters} />
          </Report.Actions>
        </Report.Header>

        <Report.FiltersBar>
          <ReportPeriodFilter
            value={params.period}
            onChange={({ target: { value } }) => handleApplyFilters({ period: value })}
          />
          {features.multicurrency && (
            <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
          )}
          <ClientFiltersBar filters={params} onChange={handleApplyFilters} />
          <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
          <TimeFiltersBar filters={params} onChange={handleApplyFilters} />
          <TaskFiltersBar filters={params} onChange={handleApplyFilters} />
          <MemberFiltersBar filters={params} onChange={handleApplyFilters} />
          <YesNoFilter
            icon="gear"
            placeholder="Show Notes"
            value={showNotes ? 'yes' : 'no'}
            onChange={({ target: { value } }) => setShowNotes(value === 'yes')}
          />
        </Report.FiltersBar>

        {(() => {
          switch (query.status) {
            case 'loading':
            case 'filtering':
              return <PageLoader />;

            default:
              return (
                <>
                  <Data
                    query={query}
                    onLoadMore={loadMore}
                    onSort={handleSort}
                    onRowClick={handleRowClick}
                    showNotes={showNotes}
                    setShowNotes={setShowNotes}
                    onCurrencyChange={handleCurrencyChange}
                  />
                  <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
                </>
              );
          }
        })()}
      </Report>

      {drawer &&
        {
          edit: () => (
            <EditTimeEntry
              id={drawer.entry.id}
              memberId={drawer.entry.member.id}
              onSubmit={(body) => api.www.workspaces(workspace.id).timeAdmin(drawer.entry.id).update(body)}
              onConfirmDelete={(id) => api.www.workspaces(workspace.id).timeAdmin(id).delete()}
              onSaved={handleRowSaved}
              onDeleted={fetchData}
              onTimerChange={fetchData}
              onClose={handleCloseDrawer}
            />
          ),
          view: () => <ViewTimeEntry id={drawer.entry.id} onClose={handleCloseDrawer} />,
        }[drawer.mode]()}
    </>
  );
}

function Data({ query, onLoadMore, onSort, onRowClick, showNotes }) {
  const { report, params } = query;

  const metrics = useMemo(() => {
    const include = (metric) => _.isEmpty(params.billableTypes) || _.includes(params.billableTypes, metric);

    const metrics = [];

    if (include(timeBillableTypes.billable))
      metrics.push({ label: 'Client Billable', value: report.totals.billable, color: colors.success, key: 'billable' });
    if (include(timeBillableTypes.non_billable))
      metrics.push({
        label: 'Client Non-Billable',
        value: report.totals.nonBillable,
        color: colors.danger,
        key: 'non_billable',
      });
    if (include(timeBillableTypes.internal))
      metrics.push({ label: 'Internal', value: report.totals.internal, color: colors.warning, key: 'internal' });
    if (include(timeBillableTypes.time_off))
      metrics.push({ label: 'Time Off', value: report.totals.timeOff, color: colors.primary50, key: 'time_off' });

    return metrics;
  }, [params, report]);

  return (
    <>
      <Report.Summary fade={query.loading.summary}>
        <Widget>
          <Meter>
            {metrics.map((metric) => (
              <Meter.Value
                key={metric.key}
                label={metric.label}
                value={metric.value}
                color={metric.color}
                tooltip={
                  <>
                    <Hours value={metric.value} /> hours (<Percent value={metric.value / report.totals.total} />)
                  </>
                }
              />
            ))}
          </Meter>

          <Metrics>
            {metrics.map((metric) => (
              <Metric key={metric.key}>
                <Metric.Value>
                  <Hours value={metric.value} />
                </Metric.Value>
                <Metric.Label>{metric.label}</Metric.Label>
              </Metric>
            ))}
            <Metric>
              <Metric.Value>
                <Hours value={report.totals.total} />
              </Metric.Value>
              <Metric.Label>Total</Metric.Label>
            </Metric>
          </Metrics>
        </Widget>
      </Report.Summary>

      <Report.Table>
        <Table>
          <Table.Header>
            <Table.Column width="3.5rem" />
            <Table.Column name="date" width="7.5rem" onSort={onSort} sort={params.sort}>
              Date
            </Table.Column>
            <Table.Column name="member.name" minWidth="16rem" onSort={onSort} sort={params.sort}>
              Member/Type
            </Table.Column>
            <Table.Column name="project.name" minWidth="16rem" onSort={onSort} sort={params.sort}>
              Project/Client
            </Table.Column>
            <Table.Column name="role.name" minWidth="16rem" onSort={onSort} sort={params.sort}>
              Role/Task
            </Table.Column>
            <Table.Column name="statusId" minWidth="16rem">
              Approver(s)
            </Table.Column>
            <Table.Column name="running_hours" width="7rem" align="right" onSort={onSort} sort={params.sort}>
              Hours
            </Table.Column>
            <Table.Column width="3.5rem" />
          </Table.Header>

          <Table.Body fade={query.loading.table}>
            {report.records.results.map((entry) => {
              const hasNotes = !!(showNotes && entry.notes);

              const handleRowClick = () => onRowClick(entry);

              return (
                <Table.Row style={{ flexWrap: 'wrap' }} onClick={handleRowClick} key={entry.id}>
                  <Table.Cell>
                    <BillableIcon value={entry.isActuallyBillable} />
                  </Table.Cell>
                  <Table.Cell>
                    <DateTime value={entry.date} />
                  </Table.Cell>
                  <Table.Cell>
                    <p>
                      {entry.member.name}
                      <Byline>{entry.member.employmentType.name}</Byline>
                    </p>
                  </Table.Cell>
                  <Table.Cell>
                    {entry.typeId === 'project_time' ? (
                      entry.project && (
                        <p>
                          {entry.project.name}
                          <Byline>{entry.project.client.name}</Byline>
                        </p>
                      )
                    ) : (
                      <p>{entry.type.name}</p>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {entry.typeId === 'project_time' ? (
                      entry.project && (
                        <p>
                          {entry.project?.useRoles && entry.role?.name}
                          {entry.task && <Byline>{entry.task.name}</Byline>}
                        </p>
                      )
                    ) : (
                      <p>{entry.timeOffType?.name}</p>
                    )}
                  </Table.Cell>

                  <Table.Cell>
                    <FirstApprover entry={entry} />
                    <Approvers entry={entry} />
                  </Table.Cell>

                  <Table.Cell>
                    <Hours value={entry.hours} />
                  </Table.Cell>

                  <Table.Cell>{entry.isLocked && <TimeLockIcon value={entry.lockStatusId} />}</Table.Cell>
                  {hasNotes && <TimeEntryNotes>{entry.notes}</TimeEntryNotes>}
                </Table.Row>
              );
            })}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell />
              <Table.Cell>Total</Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell>
                <Hours value={report.totals.total} />
              </Table.Cell>
              <Table.Cell />
            </Table.Row>

            {report.records.total > report.records.results.length && <Table.Loader onIntersecting={onLoadMore} />}
          </Table.Body>

          <Table.Status
            total={report.records.total}
            label="Time Entry"
            isLoading={query.status !== 'ready'}
            partial={report.partialResults}
          />
        </Table>
      </Report.Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ReportPeriodFilter
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <TaskFiltersGroup filters={filters} onChange={handleFilter} />

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />

      <TimeFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

export default TimeEntriesPendingApproval;
